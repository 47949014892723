import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, MenuItem, Paper, TextField, Typography, Snackbar, Unstable_Grid2 as Grid2 } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const ZohoForm = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const formSubmitted = urlParams.get('cf') === 'true';

    const initialFormData = {
        'First Name': '',
        'Contact Name': '',
        'Phone': '',
        'Email': '',
        'Classification': 'Technical support', // Default to the first option
        'Subject': '',
        'Description': ''
    };

    useEffect(() => {
        if (formSubmitted) {
            setOpen(true);

            // Remove 'cf' from the URL
            urlParams.delete('cf');
            navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
        }
    }, [formSubmitted, navigate, location.pathname, urlParams]);

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setErrors({
            ...errors,
            [e.target.name]: ''
        });
    };

    const sanitizeInput = (input) => {
        const div = document.createElement('div');
        div.textContent = input;
        return div.innerHTML;
    };

    const handleSubmit = (e) => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Validate all required fields
        if (!formData['First Name']) {
            newErrors['First Name'] = 'First Name is required';
        }
        if (!formData['Contact Name']) {
            newErrors['Contact Name'] = 'Last Name is required';
        }
        if (!formData['Email'] || !emailRegex.test(formData['Email'])) {
            newErrors['Email'] = 'Please enter a valid email address';
        }
        if (!formData['Classification']) {
            newErrors['Classification'] = 'Please select a request type';
        }
        if (!formData['Subject']) {
            newErrors['Subject'] = 'Subject is required';
        }
        if (!formData['Description']) {
            newErrors['Description'] = 'Description is required';
        }

        if (Object.keys(newErrors).length > 0) {
            e.preventDefault();
            setErrors(newErrors);
            return;
        }

        // Sanitize inputs before submission
        const sanitizedData = {
            'First Name': sanitizeInput(formData['First Name']),
            'Contact Name': sanitizeInput(formData['Contact Name']),
            'Phone': sanitizeInput(formData['Phone']),
            'Email': sanitizeInput(formData['Email']),
            'Classification': sanitizeInput(formData['Classification']),
            'Subject': sanitizeInput(formData['Subject']),
            'Description': sanitizeInput(formData['Description'])
        };

        setFormData(sanitizedData);
    };

    return (
        <Paper elevation={0} sx={{ p: 2, background: '#f6f6f6', color: 'text.primary', borderRadius: '16px' }}>
            <Typography variant='h4' fontWeight={800}>Contact Support</Typography>
            <Typography variant='h5' fontWeight={200}>How can we assist you?</Typography>
            <Box
                component="form"
                name='zsWebToCase_1027151000000282329'
                action="https://desk.zoho.com/support/WebToCase"
                encType='multipart/form-data'
                method="POST"
                sx={{ mt: 2 }}
                onSubmit={handleSubmit}
            >
                {/* Hidden Fields for Zoho */}
                <input type='hidden' name='xnQsjsdp' value='edbsne65561827cc5ea4949020f59b3ec5e11' />
                <input type='hidden' name='xmIwtLD' value='edbsnafb5ae295bff9652135132f8c83be71f279122174edba29113ab67bc80f4a897' />
                <input type='hidden' name='xJdfEaS' value='' />
                <input type='hidden' name='actionType' value='Q2FzZXM=' />
                <input type="hidden" id="property(module)" value="Cases" />
                <input type="hidden" id="dependent_field_values_Cases" value="&#x7b;&quot;JSON_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_SELECT_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_MAP_DEP_LABELS&quot;&#x3a;&#x5b;&#x5d;&#x7d;" />
                <input type='hidden' name='returnURL' value='https&#x3a;&#x2f;&#x2f;www.fourthandsixth.com&#x2f;company&#x2f;contact&#x3f;cf&#x3d;true' />

                {/* Form Fields */}
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} md={6}>
                        <TextField
                            label="First Name"
                            name="First Name"
                            value={formData['First Name']}
                            onChange={handleChange}
                            fullWidth
                            required
                            InputLabelProps={{ style: { color: 'inherit' } }}
                            error={!!errors['First Name']}
                            helperText={errors['First Name']}
                        />
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <TextField
                            label="Last Name"
                            name="Contact Name"
                            value={formData['Contact Name']}
                            onChange={handleChange}
                            fullWidth
                            required
                            InputLabelProps={{ style: { color: 'inherit' } }}
                            error={!!errors['Contact Name']}
                            helperText={errors['Contact Name']}
                        />
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <TextField
                            label="Email"
                            name="Email"
                            value={formData['Email']}
                            onChange={handleChange}
                            fullWidth
                            required
                            type="email"
                            InputLabelProps={{ style: { color: 'inherit' } }}
                            error={!!errors['Email']}
                            helperText={errors['Email']}
                        />
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <TextField
                            label="Phone"
                            name="Phone"
                            value={formData['Phone']}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{ style: { color: 'inherit' } }}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            label="Request Type"
                            name="Classification"
                            value={formData['Classification']}
                            onChange={handleChange}
                            fullWidth
                            required
                            select
                            InputLabelProps={{ style: { color: 'inherit' } }}
                            error={!!errors['Classification']}
                            helperText={errors['Classification']}
                        >
                            <MenuItem value="Technical support">Technical support</MenuItem>
                            <MenuItem value="Data request">Data request</MenuItem>
                            <MenuItem value="Other questions">Other questions</MenuItem>
                            <MenuItem value="Licensing and billing questions">Licensing and billing questions</MenuItem>
                            <MenuItem value="Suggest a new feature">Suggest a new feature</MenuItem>
                            <MenuItem value="Suggest improvement">Suggest improvement</MenuItem>
                            <MenuItem value="Report a bug">Report a bug</MenuItem>
                        </TextField>
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            label="Subject"
                            name="Subject"
                            value={formData['Subject']}
                            onChange={handleChange}
                            fullWidth
                            required
                            InputLabelProps={{ style: { color: 'inherit' } }}
                            error={!!errors['Subject']}
                            helperText={errors['Subject']}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            label="Description"
                            name="Description"
                            value={formData['Description']}
                            onChange={handleChange}
                            fullWidth
                            required
                            multiline
                            rows={4}
                            InputLabelProps={{ style: { color: 'inherit' } }}
                            error={!!errors['Description']}
                            helperText={errors['Description']}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
                            <Button variant="contained" color="primary" type="submit" disableElevation sx={{ mr: 2 }}>
                                Submit
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    setFormData(initialFormData);
                                    setErrors({});
                                }}
                            >
                                Reset
                            </Button>
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message="Your contact request has been successfully submitted!"
            />
        </Paper >
    );
};

export default ZohoForm;
