import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress, Box, IconButton, Unstable_Grid2 as Grid, Link as MUILink } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintIcon from '@mui/icons-material/Print';
import { Page, Link, Text, View, Document, StyleSheet, Font, PDFDownloadLink, Svg, Path } from '@react-pdf/renderer';
import { Table } from './reports/PDFTable';

Font.register({
    family: 'Roboto',
    fonts: [
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-Light.ttf', fontWeight: 'light' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-Regular.ttf', fontWeight: 'normal' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-Medium.ttf', fontWeight: 'medium' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-Bold.ttf', fontWeight: 'bold' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-Black.ttf', fontWeight: 'heavy' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-ThinItalic.ttf', fontWeight: 'thin', fontStyle: 'italic' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-LightItalic.ttf', fontWeight: 'light', fontStyle: 'italic' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-Italic.ttf', fontWeight: 'normal', fontStyle: 'italic' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-MediumItalic.ttf', fontWeight: 'medium', fontStyle: 'italic' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-BoldItalic.ttf', fontWeight: 'bold', fontStyle: 'italic' },
        { src: 'https://www.fourthandsixth.com/fonts/Roboto-BlackItalic.ttf', fontWeight: 'heavy', fontStyle: 'italic' },
    ]
});

// Styles for the PDF document
const styles = StyleSheet.create({
    page: {
        padding: 60,
        fontFamily: 'Roboto',
        fontSize: 10,
        fontWeight: 'normal',
    },
    view: {
        marginBottom: 20,
    },
    title: {
        fontSize: 40,
        fontWeight: 'heavy',

    },
    legal: {
        fontSize: 12,
        marginBottom: 16,
    },
    summary: {
        fontSize: 10,
        marginBottom: 16,
    },
    revision: {
        fontSize: 10,
        marginBottom: 16,
        fontWeight: 'light',
    },
    section: {
        fontSize: 14,
        marginBottom: 6,
        fontWeight: 'bold',
    },
    indent: {
        marginLeft: 16,
    },
    normal: {
        fontWeight: 'normal',
    },
    bold: {
        fontWeight: 'bold',
    },
    italic: {
        fontStyle: 'italic',
    },
    underline: {
        textDecoration: 'underline',
    },
    strikethrough: {
        textDecoration: 'line-through',
    },
    link: {
        color: '#336699',
        textDecoration: 'none',
    },
    ul: { marginVertical: 6, paddingLeft: 20 },
    li: { marginBottom: 2, flexDirection: 'row', alignItems: 'flex-start' },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: '#333333',
    },
});

// Utility function to parse and style the markup for the PDF
const parsedTextForPDF = ({ text, tableData, style }) => {
    const parts = text.split(/(\$\$[^$]+\$\$|&&[^&]+&&)/);  // Split by both table and unordered list tags
    const elements = [];
    let textParts = [];  // Collects parts that will be wrapped in a Text component

    const addTextElements = () => {
        if (textParts.length > 0) {
            elements.push(<Text style={style} key={`text-${elements.length}`}>{textParts}</Text>);
            textParts = [];  // Reset for next block of text content
        }
    };

    parts.forEach((part, index) => {
        const tableMatch = part.match(/^\$\$(.*?)\$\$$/);
        const listMatch = part.match(/^&&(.*?)&&$/);

        if (tableMatch) {
            addTextElements();  // Ensure any prior text is closed before inserting a table
            const tableName = tableMatch[1];  // Extract the name between the $$ symbols
            const table = tableData && tableData.find(table => table.name === tableName);
            if (table) {
                elements.push(<Table key={`table-${index}`} data={table} parser={parsePDFText} />);
            }
        } else if (listMatch) {
            addTextElements();  // Ensure any prior text is closed before inserting a list
            const listItems = listMatch[1].split(/!!(.*?)!!/g).filter(item => item.trim() !== '');
            elements.push(
                <View key={`ul-${index}`} style={[styles.ul, style]}>
                    {listItems.map((item, idx) => (
                        <Text key={`li-${index}-${idx}`} style={styles.li}>• {parsePDFText(item)}</Text>
                    ))}
                </View>
            );
        } else {
            // Accumulate text segments to be wrapped together in a Text component
            textParts.push(...parsePDFText(part));
        }
    });

    addTextElements();  // Add any remaining text elements after loop

    return <>{elements}</>;
};

// Function to parse text segments (similar to your existing logic)
const parsePDFText = (text) => {
    const elements = [];
    let lastIndex = 0;

    // Updated regex to include && and !! tags for ul and li respectively
    const regex = /(\*\*(.*?)\*\*)|(\*(.*?)\*)|(__([^_]+)__)|(~~(.*?)~~)|(\[(.*?)\]\((.*?)\))/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
        if (match.index > lastIndex) elements.push(text.substring(lastIndex, match.index));

        if (match[1]) {
            elements.push(<Text key={match.index} style={styles.bold}>{match[2]}</Text>);
        } else if (match[3]) {
            elements.push(<Text key={match.index} style={styles.italic}>{match[4]}</Text>);
        } else if (match[5]) {
            elements.push(<Text key={match.index} style={styles.underline}>{match[6]}</Text>);
        } else if (match[7]) {
            elements.push(<Text key={match.index} style={styles.strikethrough}>{match[8]}</Text>);
        } else if (match[9]) {
            elements.push(<Link key={match.index} style={styles.link} src={match[11]}>{match[10]}</Link>);
        }

        lastIndex = regex.lastIndex;
    }

    if (lastIndex < text.length) elements.push(text.substring(lastIndex));

    return elements;
};

// Utility function to parse and style the markup for the website using MUI
const parseText = (text, tableData) => {
    const elements = [];
    let lastIndex = 0;

    // Updated regex to catch the $$data$$ tag and bullet points marked with `-->`
    const regex = /(\*\*(.*?)\*\*)|(\*(.*?)\*)|(__([^_]+)__)|(~~(.*?)~~)|(\[(.*?)\]\((.*?)\))|(\n)|(\$\$(.*?)\$\$)|(&&(.*?)&&)|(!!(.*?)!!)/gm;
    let match;

    while ((match = regex.exec(text)) !== null) {
        if (match.index > lastIndex) {
            elements.push(text.substring(lastIndex, match.index));
        }

        if (match[1]) {
            elements.push(<Typography key={match.index} component="span" fontWeight="bold">{match[2]}</Typography>);
        } else if (match[3]) {
            elements.push(<Typography key={match.index} component="span" fontStyle="italic">{match[4]}</Typography>);
        } else if (match[5]) {
            elements.push(<Typography key={match.index} component="span" sx={{ textDecoration: 'underline' }}>{match[6]}</Typography>);
        } else if (match[7]) {
            elements.push(<Typography key={match.index} component="span" sx={{ textDecoration: 'line-through' }}>{match[8]}</Typography>);
        } else if (match[9]) {
            elements.push(
                <MUILink key={match.index} underline='hover' color={'#336699'} href={match[11]} target="_blank" rel="noopener noreferrer">
                    {match[10]}
                </MUILink>
            );
        } else if (match[12]) {
            elements.push(<br key={match.index} />);
        } else if (match[13]) {
            // Handle the $$data$$ tag
            const matchName = match[14];
            const table = tableData.find(table => table.name === matchName);

            if (table) {
                elements.push(
                    <Grid key={`table-${matchName}`} container border={{ xs: 0, md: 1 }} spacing={2} sx={{ mx: 'auto', fontSize: '.85em' }}>
                        <Grid container item={'header'} xs={12} key="header" sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {table.headers.map((header, idx) => (
                                <Grid item={`hdr-${idx}`} key={`hdr-${idx}`} sx={{ textAlign: 'center', fontWeight: 'bold', width: { xs: '100%', md: header.width } }} border={1}>
                                    {header.display}
                                </Grid>
                            ))}
                        </Grid>
                        {table.items.map((row, rowIndex) => (
                            <Grid container item={`row-${rowIndex}`} xs={12} key={`row-${rowIndex}`} border={{ xs: 2, md: 0 }} margin={{ xs: 1, md: -1 }}>
                                {table.headers.map((header, cellIndex) => (
                                    <Grid item={`cell-${rowIndex}-${cellIndex}`} key={`cell-${rowIndex}-${cellIndex}`} sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', width: { xs: '100%', md: header.width } }} border={1}>
                                        {parseText(row[header.name])}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                );
            }
        } else if (match[15]) {
            elements.push(<ul key={match.index} style={{ listStyleType: 'disc' }}>
                {parseText(match[16], tableData)}
            </ul>);
        } else if (match[17]) {
            elements.push(<li key={match.index} style={{ listStyleType: 'disc' }}>
                {parseText(match[18], tableData)}
            </li>);
        }


        lastIndex = regex.lastIndex;
    }

    if (lastIndex < text.length) {
        elements.push(text.substring(lastIndex));
    }

    return elements;
};

const Icon = ({ fixed = false, style }) => (
    <Svg fixed={fixed} style={style} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.3 64.18">
        <Path fill={style.color} d="M22.53,49.27l12.61,8.22c3.21-3.57,5.16-8.29,5.16-13.46v-6.34l-17.77,11.58Z" />
        <Path fill={style.color} d="M0,37.69v6.34c0,11.13,9.02,20.15,20.15,20.15h0c4.99,0,9.56-1.82,13.08-4.83L0,37.69Z" />
        <Path fill={style.color} d="M20.15,0h0C9.02,0,0,9.02,0,20.15v14.43l20.15,13.14,20.15-13.14v-14.43C40.3,9.02,31.28,0,20.15,0ZM20.15,33.6c-7.33,0-13.27-5.94-13.27-13.27s5.94-13.27,13.27-13.27,13.27,5.94,13.27,13.27-5.94,13.27-13.27,13.27Z" />
        <Path fill={style.color} d="M18.19,19.9c-.61,0-1.11-.5-1.11-1.11,0-1.08-.88-1.95-1.95-1.95s-1.95.87-1.95,1.95c0,.62-.5,1.11-1.11,1.11s-1.11-.5-1.11-1.11c0-2.3,1.87-4.18,4.18-4.18s4.18,1.87,4.18,4.18c0,.62-.5,1.11-1.11,1.11Z" />
        <Path fill={style.color} d="M28.24,19.9c-.61,0-1.11-.5-1.11-1.11,0-1.08-.88-1.95-1.95-1.95s-1.95.87-1.95,1.95c0,.62-.5,1.11-1.11,1.11s-1.11-.5-1.11-1.11c0-2.3,1.87-4.18,4.18-4.18s4.18,1.87,4.18,4.18c0,.62-.5,1.11-1.11,1.11Z" />
    </Svg>
)

// Main component to display the accordion and generate the PDF
const JsonGeneratePage = ({ fileName }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}${fileName}`);
                if (!response.ok) {
                    throw new Error('Failed to load the JSON file.');
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [fileName]);

    const DownloadPDF = () => (
        <Document>
            <Page size='LETTER' orientation='portrait' style={styles.page}>
                <Icon fixed={true} style={{ width: 18, height: 18, position: 'absolute', bottom: 30, right: 60, color: '#333333' }} />
                <Icon style={{ width: 48, height: 48, position: 'absolute', top: 60, right: 60, color: '#333333' }} />
                <Text style={styles.title}>{data.title}</Text>
                <Text style={styles.revision}>Revised: {data.revision_date}</Text>
                {parsedTextForPDF({ text: data.legal, style: styles.legal})}
                {parsedTextForPDF({ text: data.summary, style: styles.summary })}
                {data.items.map((item, index) => (
                    <View style={styles.view} key={index}>
                        <Text style={styles.section}>{item.title}</Text>
                        {parsedTextForPDF({ text: item.description, tableData: item.table, style: styles.indent})}
                    </View>
                ))}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
            </Page>
        </Document>
    );

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Box sx={{ ml: 'auto' }}>
                    <PDFDownloadLink document={<DownloadPDF />} fileName={`${data.name}.pdf`}>
                        {({ blob, url, loading, error }) =>
                            loading ? <CircularProgress /> : <IconButton><PrintIcon /></IconButton>
                        }
                    </PDFDownloadLink>
                </Box>
            </Box>
            <Typography variant="h2" fontSize={{ xs: 36, md: 60 }} fontWeight={800}>{data.title}</Typography>
            <Typography variant="subtitle1" fontWeight={300} paragraph>Revised: {data.revision_date}</Typography>
            <Typography variant="body1" fontWeight={400}>{parseText(data.legal, data.table)}</Typography>
            <Typography variant="body1" fontWeight={400} sx={{ my: 4 }}>{parseText(data.summary, data.table)}</Typography>
            {data.items.map((item, index) => (
                <Accordion key={index} elevation={0} disableGutters
                    sx={{
                        borderTop: 'none',
                        '&:before': {
                            display: 'none'
                        }
                    }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}

                        aria-controls={`panel${index}-content`}
                        sx={{
                            padding: 0,
                            minHeight: 0,
                            '& .MuiAccordionSummary-content': {
                                margin: '8px 0px',
                            }
                        }}
                    >
                        <Typography variant="h6" fontWeight={700}>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div" variant="body1">
                            {parseText(item.description, item?.table)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default JsonGeneratePage;
