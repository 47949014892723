import React from 'react';
import { Grid, Typography, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomChip from './CustomChip';

const BottomNavigation = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const content = [
        {
            title: 'Products',
            links: [
                { text: 'RaisedUp', path: '#', chip: 'Coming Soon' },
            ],
        },
        {
            title: 'Company',
            links: [
                { text: 'Contact', path: '/company/contact' },
            ],
        },
        {
            title: 'Legal',
            links: [
                { text: 'Terms of Service', path: '/legal/terms' },
                { text: 'Privacy Policy', path: '/legal/privacy' },
                { text: 'Cookie Policy', path: '/legal/cookies' },
            ],
        },
    ];

    return (
        <Grid container spacing={0}>
            {content.map((section, index) => (
                <Grid item xs={12} md={3} key={index}>
                    {isXs ? (
                        <Accordion
                            disableGutters
                            elevation={0}
                            sx={{
                                backgroundColor: 'transparent',
                                '&:before': { display: 'none' },
                                color: 'text.secondary',
                            }}
                            
                        >
                            <AccordionSummary sx={{ minHeight: 0, my: 0, p: 0, '& .MuiAccordionSummary-content': { my: '2px' } }} expandIcon={<ExpandMoreIcon sx={{ color: "text.secondary" }} />}>
                                <Typography variant="h6" fontWeight={600}>{section.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                                {section.links.map((link, linkIndex) => (
                                    <Typography
                                        key={linkIndex}
                                        component={Link}
                                        to={link.path}
                                        color="inherit"
                                        variant="body1"
                                        fontWeight={200}
                                        sx={{ display: 'block', textDecoration: 'none' }}
                                    >
                                        {link.text}
                                        {link.chip && (
                                            <CustomChip label={link.chip} />
                                        )}
                                    </Typography>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <Stack>
                            <Typography color="inherit" variant="h6" fontWeight={600}>{section.title}</Typography>
                            {section.links.map((link, linkIndex) => (
                                <Typography
                                    key={linkIndex}
                                    component={Link}
                                    to={link.path}
                                    color="inherit"
                                    variant="body1"
                                    fontWeight={200}
                                    sx={{ display: 'block', textDecoration: 'none' }}
                                >
                                    {link.text}
                                    {link.chip && (
                                        <CustomChip label={link.chip} />
                                    )}
                                </Typography>
                            ))}
                        </Stack>
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default BottomNavigation;
