import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import { responsiveFontSizes, createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { BreadcrumbProvider } from './providers/BreadcrumbProvider';

let theme = createTheme({
  palette: {
    text: {
      primary: 'rgb(23,24,35)',
      secondary: 'rgba(255,255,255,.9)',
    },
    primary: {
      main: 'rgb(23,24,35)',
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: 'rgb(255, 255, 255)',
      contrastText: "rgb(23,24,35)",
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          underline: 'hover',
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

const Root = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BreadcrumbProvider>
          <App />
        </BreadcrumbProvider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Root />);