import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomChip from '../CustomChip';

const Section2 = () => {
    const sectionRef = useRef(null);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setAnimate(true);
                    observer.disconnect(); // Disconnect observer after first intersection
                }
            },
            {
                threshold: 0.75, // Trigger when 10% of the section is visible
            }
        );

        const currentRef = sectionRef.current; // Store the current value of sectionRef

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef); // Use the stored ref value in cleanup
            }
        };
    }, []);

    return (
        <Box
            ref={sectionRef}
            sx={{
                width: '100%',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
            }}
        >
            <Container
                maxWidth={"lg"}
                sx={{
                    minHeight: ['80dvh', '80vh'], // Fallback to 80vh if 80dvh isn't supported
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid2
                    container
                    spacing={2}
                    sx={{
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                >
                    <Grid2
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            py: 2,
                            transform: animate ? 'translateX(0)' : 'translateX(-100%)',
                            opacity: animate ? 1 : 0,
                            transition: 'transform 2s ease, opacity 2s ease',
                        }}
                    >
                        <Box
                            component="img"
                            src="/images/ss_20240813_113857.png"
                            alt="RaisedUp Logo"
                            sx={{ width: { xs: 300, md: 500 }, borderRadius: '12px 12px 12px 50px' }}
                        />
                    </Grid2>
                    <Grid2
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: { xs: 'center', md: 'start' },
                            justifyContent: 'center',
                            py: 2,
                            transform: animate ? 'translateY(0)' : 'translateY(100%)',
                            opacity: animate ? 1 : 0,
                            transition: 'transform 2s ease, opacity 2s ease',
                        }}
                    >
                            <Box
                                component="img"
                                src="/images/raisedup_logo_dark.svg"
                                alt="RaisedUp Logo"
                                sx={{ height: { xs: 70, md: 90 }, pb: 2 }}
                            />
                        <Typography variant='h4' fontWeight={200} paragraph>
                            Fundraising platform for small to midsize organizations.
                        </Typography>
                        <Button variant={"outlined"} color={"secondary"} size={"large"} component={Link} to="#" endIcon={<KeyboardArrowRightIcon />} sx={{ mb: 2 }}>
                        Start Raising <CustomChip label={"Coming Soon"} sx={{ position: 'absolute', top: 0, right: 0, transform: 'translate(50%, -50%)' }} />
                        </Button>
                        <Typography variant='body1' fontWeight={200} paragraph>
                            Manage your organizations fundraising efforts on a single platform.  Host events, build campaigns, and communicate with your followers.
                        </Typography>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    );
};

export default Section2;
