import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import UpdateIcon from '@mui/icons-material/Update';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

export const GradientIcon = (icon) => (
    <svg width={0} height={0}>
        <linearGradient id="gradientColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(10)">
            <stop offset='0%' stopColor="#336699" />,
            <stop offset='100%' stopColor="#e81cff" />
        </linearGradient>
    </svg>
)

const Section3 = () => {
    return (
        <Box sx={{
            width: '100%',
            color: 'primary.main'
        }}>
            <Container
                maxWidth={"lg"}
                sx={{
                    minHeight: ['80dvh', '80vh'],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 2,

                }}
            >
                <GradientIcon />
                <Grid2 container spacing={4}>
                    <Grid2 xs={12} md={6}>
                        <UpdateIcon sx={{ fill: "url(#gradientColors)", fontSize: 60, mb: 1 }} />
                        <Typography variant='h4' fontWeight={700} paragraph>Always Up-to-Date</Typography>
                        <Typography variant='body2' paragraph>
                            Our SaaS solutions ensure your software is always current with the latest features and enhancements. This automatic updating process lets you focus on your business without worrying about manual updates. Stay ahead with tools that evolve with industry standards.
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <ShieldOutlinedIcon sx={{ fill: "url(#gradientColors)", fontSize: 60, mb: 1 }} />
                        <Typography variant='h4' fontWeight={700} paragraph>Enhanced Security</Typography>
                        <Typography variant='body2' paragraph>
                            Security is our priority, with regular updates to protect against emerging threats. We handle data protection, so you can operate with peace of mind. Our cutting-edge security measures help you mitigate risks and safeguard sensitive information.
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <AssuredWorkloadOutlinedIcon sx={{ fill: "url(#gradientColors)", fontSize: 60, mb: 1 }} />
                        <Typography variant='h4' fontWeight={700} paragraph>Secure Transactions</Typography>
                        <Typography variant='body2' paragraph>
                            We ensure all transactions are encrypted and securely handled by your processors. By not storing sensitive information on our platforms, we reduce the risk of breaches. This approach protects your clients and reinforces your organization's security reputation.
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <GroupsOutlinedIcon sx={{ fill: "url(#gradientColors)", fontSize: 60, mb: 1 }} />
                        <Typography variant='h4' fontWeight={700} paragraph>Seamless Collaboration</Typography>
                        <Typography variant='body2' paragraph>
                            Our platforms enable seamless collaboration from any location and on any device. Keep your team connected and productive, regardless of geography. Enhance teamwork and streamline operations with tools that adapt to your needs.
                        </Typography>
                    </Grid2>
                </Grid2>

            </Container>
        </Box>
    )
}

export default Section3;