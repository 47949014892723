import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    table: {
        width: '100%',
        borderWidth: 2,
        display: 'flex',
        flexDirection: 'column',
        marginVertical: 12,
    },
    header: {
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    cell: {
        borderWidth: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 4,
    },
});

export const Table = ({ data, parser }) => (
    <View style={styles.table} wrap={false}>
        <TableHeader headers={data.headers} />
        <TableItems headers={data.headers} items={data.items} parser={parser} />
    </View>
);

export const TableHeader = ({ headers }) => (
    <View style={styles.row}>
        {headers.map((header, index) => (
            <View
                style={[styles.cell, styles.header, { flexBasis: header.width }]}
                key={index}
            >
                <Text>{header.display || ''}</Text>
            </View>
        ))}
    </View>
);

export const TableItems = ({ headers, items, parser }) => (
    items.map((row, rowIndex) => (
        <TableRow key={rowIndex} row={row} headers={headers} parser={parser} />
    ))
);

export const TableRow = ({ row, headers, parser }) => (
    <View style={styles.row}>
        {headers.map((header, index) => (
            <View
                key={index}
                style={[styles.cell, { flexBasis: header.width }]}
            >
                <Text>{parser(row[header?.name]) || ''}</Text>
            </View>
        ))}
    </View>
);
