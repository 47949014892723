import { useEffect } from 'react';
import { useBreadcrumbs } from '../providers/BreadcrumbProvider';

export const useBreadcrumb = (breadcrumb) => {
    const { addBreadcrumb } = useBreadcrumbs();
   
    useEffect(() => {
        if (breadcrumb) addBreadcrumb(breadcrumb);
        // eslint-disable-next-line
    }, []);
};