import React from 'react';
import { Container, Paper, Typography } from '@mui/material';
import { useBreadcrumb } from '../../utility/useBreadcrumb';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ZohoForm from './ZohoForm';

const Contact = () => {
    useBreadcrumb([
        { name: 'Company' }, 
        { name: 'Contact', path: '/company/contact' }
    ]);

    return (
        <Container maxWidth={"lg"} sx={{ pt: 5 }}>
            <Grid2 container spacing={4}>
                <Grid2 xs={12}>
                    <Typography variant='h2' fontWeight={800}>Contact Support</Typography>
                    <Typography variant='h4' fontWeight={200}>Speak with one of our team to get you the help you need.</Typography>
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <Paper elevation={0} sx={{ p: 2, background: 'linear-gradient(160deg, #336699, #e81cff)', color: 'text.secondary', borderRadius: '16px' }}>
                        <Typography variant='h4' fontWeight={800}>Headquarters - Charlotte, NC</Typography>
                        <Typography variant='caption' fontWeight={400} paragraph>Email is generally faster</Typography>
                        <Typography variant='h6' fontWeight={300} lineHeight={1.2}>
                            Fourth and Sixth Inc.<br />
                            301 E John St # 273<br />
                            Matthews, NC 28105-4837
                        </Typography>
                    </Paper>
                    <Paper elevation={0} sx={{ mt: 4, p: 2, background: 'linear-gradient(160deg, #333, #666)', color: 'text.secondary', borderRadius: '16px' }}>
                        <Typography variant='h4' fontWeight={800}>General Support</Typography>
                        <Typography variant='caption' fontWeight={400} paragraph>Response time is usually within 1 business day</Typography>
                        <Typography variant='h5' fontWeight={300}>
                            support@fourthandsixth.com
                        </Typography>
                    </Paper>
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <ZohoForm />
                </Grid2>
            </Grid2>
        </Container>
    );
};

export default Contact;
