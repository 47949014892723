import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const GradientIcon = (icon) => (
    <svg width={0} height={0}>
        <linearGradient id="gradientColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(10)">
            <stop offset='0%' stopColor="#336699" />, 
            <stop offset='100%' stopColor="#e81cff" />
        </linearGradient>
    </svg>
)

const Section1 = () => {
    return (
        <Container
            maxWidth={"lg"}
            sx={{
                minHeight: ['80dvh', '80vh'], // Fallback to 80vh if 80dvh isn't supported
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid2
                container
                spacing={4}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: { xs: 'center', md: 'left' },
                }}
            >
                <Grid2 xs={12} md={6}>
                    <Typography variant={"h2"} fontWeight={800} sx={{
                        background: 'linear-gradient(90deg, #336699, #e81cff)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                    }}>
                        Propel your organization to the cloud
                    </Typography>
                    <Typography variant='h4' fontWeight={200}>
                        Our SaaS solutions can relieve the bottlenecks slowing down your growth.
                    </Typography>
                </Grid2>
                <Grid2 xs={12} md={6} sx={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box
                        component="img"
                        src="/images/rocket.svg"
                        alt="Rocket through cloud"
                        sx={{ width: { xs: 300, md: 400 } }}
                    />
                </Grid2>
            </Grid2>
        </Container>
    )
}

export default Section1;