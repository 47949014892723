import React from 'react';
import Section1 from './home/Section1';
import Section2 from './home/Section2';
import Section3 from './home/Section3';
import { useBreadcrumb } from '../utility/useBreadcrumb';

const HomePage = () => {
    useBreadcrumb();
    
    return (
        <>
        <Section1 />
        <Section2 />
        <Section3 />
        </>
    );
}

export default HomePage;
