import React, { createContext, useContext, useState } from 'react';

const BreadcrumbContext = createContext();

export const BreadcrumbProvider = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const addBreadcrumb = async (breadcrumb) => {
        setBreadcrumbs([]);
        breadcrumb.forEach((breadcrumb) => {
                setBreadcrumbs((prev) => [...prev, breadcrumb]);
        })
    };

    return (
        <BreadcrumbContext.Provider value={{ breadcrumbs, addBreadcrumb }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

export const useBreadcrumbs = () => {
    return useContext(BreadcrumbContext);
};
