import React from 'react';
import { Chip } from "@mui/material";

const CustomChip = ({ label, sx }) => {
    return (
        <Chip
            size="small"
            label={label}
            sx={{
                ml: 1,
                fontWeight: 400,
                textTransform: 'uppercase',
                borderRadius: '4px',
                height: '16px',
                fontSize: '10px',
                bgcolor: '#336699',
                color: '#ffffff',
                '& .MuiChip-label': {
                    px: '4px',
                },
                ...sx
            }}
        />
    )
}

export default CustomChip;