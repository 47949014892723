import React from 'react';
import { useBreadcrumb } from '../../utility/useBreadcrumb';
import { Container } from '@mui/material';
import JsonGeneratePage from '../JsonGeneratePage';

const Privacy = () => {
    useBreadcrumb([
        { name: 'Legal' }, 
        { name: 'Privacy Policy', path: '/legal/privacy' }
    ]);

    return (
        <Container maxWidth={"lg"} sx={{ pt: 2 }}>
            <JsonGeneratePage fileName={"/legal/privacy.json"} />
        </Container>
    )
}

export default Privacy;