import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { Box, AppBar, Toolbar, Container, Divider, Typography, Breadcrumbs } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useBreadcrumbs } from "../providers/BreadcrumbProvider";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import BottomNavigation from "./BottomNavigation";

const Wrapper = () => {
    const { breadcrumbs } = useBreadcrumbs();
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 5);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: ["100dvh", "100vh"],
            backgroundColor: "#ffffff"
        }}>
            <AppBar
                position="sticky"
                elevation={0}
                sx={{
                    backdropFilter: "blur(6px)",
                    backgroundColor: scrolled ? "rgba(255, 255, 255, 0.9)" : "rgba(255, 255, 255, 1)",
                    transition: "background-color 0.3s ease",
                }}
            >
                <Container maxWidth={"lg"}>
                    <Toolbar disableGutters
                        sx={{
                            display: "flex",
                            justifyContent: { xs: "center", md: "start" },
                        }}
                    >
                        <Box
                            component={Link}
                            to="/"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            <Box
                                component="img"
                                src="/images/company_logo.svg"
                                alt="Company Logo"
                                sx={{ height: { xs: 24, md: 24 } }}
                            />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
            <Box sx={{
                position: "relative",
                backgroundColor: "primary.main",
                color: "text.secondary",
                py: 2,
                mt: 5,
                "&:before": {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: "10px",
                    background: "linear-gradient(90deg, #336699, #e81cff, #336699)",
                    backgroundSize: "200% 100%",
                    animation: "moveGradient 5s linear infinite",
                }
            }}>
                <Container maxWidth={"lg"}>
                    <Box sx={{ display: "flex", alignItems: "center", height: 36, mb: 3 }}>
                        <Link to={"/"}>
                            <Box
                                component="img"
                                src="/images/company_icon_dark.svg"
                                alt="Company Logo"
                                sx={{ height: { xs: 18, md: 18 }, display: "flexItem", pr: 1, color: "inherit" }}
                            />
                        </Link>
                        {breadcrumbs.length > 0 && <NavigateNextIcon fontSize="small" sx={{ mx: 1 }} />}
                        <BreadcrumbList />
                    </Box>
                    <BottomNavigation />
                    <Grid2 container sx={{ mt: 5 }}>
                        <Grid2 xs={12} md={4} display={"flex"} alignItems={"center"}>
                            <Typography color={"inherit"} variant={"caption"} fontWeight={400} noWrap>Copyright &copy; 2024 Fourth and Sixth Inc. All rights reserved.</Typography>
                        </Grid2>
                        <Grid2 xs={12} md={8} display={"flex"} alignItems={"center"}>
                            <Typography color={"inherit"} variant={"caption"} fontWeight={200} noWrap component={Link} to="/legal/terms" sx={{ textDecoration: "none" }}>Terms of Service</Typography>
                            <Divider orientation="vertical" color={"#999999"} flexItem sx={{ mx: 1, my: "6px" }} />
                            <Typography color={"inherit"} variant={"caption"} fontWeight={200} noWrap component={Link} to="/legal/privacy" sx={{ textDecoration: "none" }}>Privacy Policy</Typography>
                            <Divider orientation="vertical" color={"#999999"} flexItem sx={{ mx: 1, my: "6px" }} />
                            <Typography color={"inherit"} variant={"caption"} fontWeight={200} noWrap component={Link} to="/company/contact" sx={{ textDecoration: "none" }}>Contact</Typography>
                        </Grid2>
                    </Grid2>
                </Container>
            </Box>
            <style>
                {`
                @keyframes moveGradient {
                    0% { background-position: 100% 50%; }
                    100% { background-position: -100% 50%; }
                }
                `}
            </style>
        </Box>
    );
};

const BreadcrumbList = () => {
    const { breadcrumbs } = useBreadcrumbs();

    return (
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb">
            {breadcrumbs.map((breadcrumb, index) => (
                <Box key={index}>
                    <Typography color={"inherit"} lineHeight={3} variant="caption" fontWeight={200} noWrap component={breadcrumb?.path && Link} to={breadcrumb?.path && breadcrumb.path} sx={{ textDecoration: "none" }}>{breadcrumb.name}</Typography>
                </Box>
            ))}
        </Breadcrumbs>
    );
};

export default Wrapper;
