import React from 'react';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Wrapper from './components/Wrapper';
import HomePage from './components/HomePage';
import Privacy from './components/legal/Privacy';
import Terms from './components/legal/Terms';
import Contact from './components/company/Contact';
import Cookies from './components/legal/Cookies';

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Wrapper />}>
          <Route index element={<HomePage />} />
          <Route path="legal">
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="cookies" element={<Cookies />} />
          </Route>
          <Route path="company">
            <Route path="contact" element={<Contact />} />
          </Route>
        </Route>
    )
  );

  return <RouterProvider router={router} />
}

export default App;


