import React from 'react';
import { useBreadcrumb } from '../../utility/useBreadcrumb';
import { Container } from '@mui/material';
import JsonGeneratePage from '../JsonGeneratePage';

const Terms = () => {
    useBreadcrumb([
        { name: 'Legal' }, 
        { name: 'Terms of Service', path: '/legal/terms' }
    ]);

    return (
        <Container maxWidth={"lg"} sx={{ pt: 2 }}>
            <JsonGeneratePage fileName={"/legal/terms.json"} />
        </Container>
    )
}

export default Terms;